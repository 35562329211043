import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import './scss/style.scss'

import CreateNewPassword from './views/pages/forgot-password/CreateNewPassword'
import ForgotPassword from './views/pages/forgot-password/Forgot-password'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// ***********
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
// ******************
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
import "./utils/axios-interceptor";

class App extends Component {
  render() {
    return (
      <Router>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/create-password"
              name="Create New Password"
              render={(props) => <CreateNewPassword {...props} />}
            />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
            <Redirect to="/dashboard" />
          </Switch>
        </React.Suspense>
      </Router>
    )
  }
}

export default App

// import React, { Component } from 'react'
// import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
// import PrivateRoute from './components/routing/PrivateRoute'
// import './scss/style.scss'
// import store from './store'
// import { loadUser } from './actions/auth'
// import setAuthToken from './utils/setAuthToken'
// // import AddMovie from './views/movies/add-movie/AddMovie'

// const loading = (
//   <div className="pt-3 text-center">
//     <div className="sk-spinner sk-spinner-pulse"></div>
//   </div>
// )

// // Containers
// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// // Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

// class App extends Component {
//   componentDidMount() {
//     if (localStorage.token) {
//       setAuthToken(localStorage.token)
//     }
//     store.dispatch(loadUser())
//   }
//   render() {
//     return (
//       <Router history={history}>
//         <React.Suspense fallback={loading}>
//           <Switch>
//             <Route exact path="/login" component={Login} />
//             {/* <PrivateRoute exact path="/movies/add-movie" component={AddMovie} /> */}
//             {/* <PrivateRoute exact path="/dashboard" component={DefaultLayout} /> */}
//             <Route path="/dashboard" name="Home" render={(props) => <DefaultLayout {...props} />} />
//             <Redirect to="/login" />
//           </Switch>
//         </React.Suspense>
//       </Router>
//     )
//   }
// }
