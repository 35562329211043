import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import alert from './alert'
import auth from './auth'
import sidebar from './sidebar'
import movie from './movie'
import user from './user'
import category from './category'
import coupon from './coupon'
import language from './language'
import cast from './cast'
import review from './review'
import usersMovies from './users-movies'
import order from './order'
import support from './support'
import joinMe from './join-me'

export default combineReducers({
  alert,
  auth,
  sidebar,
  movie,
  user,
  category,
  coupon,
  language,
  cast,
  review,
  usersMovies,
  order,
  support,
  form: formReducer,
  joinMe,
});
