export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGOUT = 'LOGOUT'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const CREATE_NEW_PASSWORD = 'CREATE_NEW_PASSWORD'
export const ADD_MOVIE = 'ADD_MOVIE'
export const ADD_MOVIE_FAIL = 'ADD_MOVIE_FAIL'
export const LIST_MOVIE = 'LIST_MOVIE'
export const LIST_MOVIE_FAIL = 'LIST_MOVIE_FAIL'
export const GET_MOVIE = 'GET_MOVIE'
export const GET_RECENT_MOVIE = 'GET_RECENT_MOVIE'
export const UPDATE_MOVIE = 'UPDATE_MOVIE'
export const DELETE_MOVIE = 'DELETE_MOVIE'
export const ADD_USER = 'ADD_USER'
export const ADD_USER_FAIL = 'ADD_USER_FAIL'
export const LIST_USER = 'LIST_USER'
export const LIST_USER_FAIL = 'LIST_USER_FAIL'
export const GET_USER = 'GET_USER'
export const GET_RECENT_USER = 'GET_RECENT_USER'
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_FAIL = 'DELETE_USER'
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const GET_CATEGORY = 'GET_CATEGORY'
export const LIST_CATEGORY = 'LIST_CATEGORY'
export const LIST_CATEGORY_FAIL = 'LIST_CATEGORY_FAIL'
export const ADD_COUPON = 'ADD_COUPON'
export const EDIT_COUPON = 'EDIT_COUPON'
export const DELETE_COUPON = 'DELETE_COUPON'
export const GET_COUPON = 'GET_COUPON'
export const LIST_COUPON = 'LIST_COUPON'
export const LIST_COUPON_FAIL = 'LIST_COUPON_FAIL'
export const ADD_LANGUAGE = 'ADD_LANGUAGE'
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE'
export const GET_LANGUAGE = 'GET_LANGUAGE'
export const LIST_LANGUAGE = 'LIST_LANGUAGE'
export const LIST_LANGUAGE_FAIL = 'LIST_LANGUAGE_FAIL'
export const ADD_CAST = 'ADD_CAST'
export const DELETE_CAST = 'DELETE_CAST'
export const GET_CAST = 'GET_CAST'
export const LIST_CAST = 'LIST_CAST'
export const LIST_CAST_FAIL = 'LIST_CAST_FAIL'
export const ADD_REVIEW = 'ADD_REVIEW'
export const DELETE_REVIEW = 'DELETE_REVIEW'
export const GET_REVIEW = 'GET_REVIEW'
export const LIST_REVIEW = 'LIST_REVIEW'
export const LIST_REVIEW_FAIL = 'LIST_REVIEW_FAIL'
export const UPLOAD_CAST_IMG = 'UPLOAD_CAST_IMG'

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const NOTIFICATION_LIST = 'NOTIFICATION_LIST'
//
export const LIST_USERS_MOVIE = 'LIST_USERS_MOVIE'
export const USERS_MOVIE_FAIL = 'USERS_MOVIE_FAIL'
export const GET_USERS_MOVIE = 'GET_USERS_MOVIE'
export const DELETE_USER_MOVIE = 'DELETE_USER_MOVIE'
//
export const UPLOAD_MOVIE = 'UPLOAD_MOVIE'
export const UPLOAD_TRAILER = 'UPLOAD_TRAILER'
export const UPLOAD_BANNERS = 'UPLOAD_BANNERS'
export const UPLOAD_SUBTITLES = 'UPLOAD_SUBTITLES'
//
export const LIST_ORDER = 'LIST_ORDER'
export const LIST_ORDER_FAIL = 'LIST_ORDER_FAIL'
export const DELETE_ORDER = 'DELETE_ORDER'
//
export const VERIFY_PASSWORD = 'VERIFY_PASSWORD'
export const SUPPORT_LIST = 'SUPPORT_LIST'
// created a type
export const JOINME_LIST = 'JOINME_LIST'

